import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  TouchableOpacity,
} from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import Button from "../custom/Button";
import axios from "axios";
import { RecipeIngredientDto } from "./recipe-details-screen";
import { ImageObjectDto } from "./recent-recipe-details-screen";
import { useRoute } from "@react-navigation/native";

// Define the type for the navigation prop for each screen
type ImageUploaderScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "ImageUploaderScreen"
>;

export interface RecipeDetailsDto {
  name: string;
  id: string;
  type: string;
  description: string;
  cook_time: string;
  servings: string;
  images: string[];
  ingredients: RecipeIngredientDto[];
  instructions: string[];
}

export interface ImageUploaderScreenParamsDTO {
  image?: ImageObjectDto;
}

export default function ImageUploaderScreen({
  navigation,
}: {
  navigation: ImageUploaderScreenNavigationProp;
}) {
  const route = useRoute();
  const [loading, setLoading] = useState<boolean>(false);

  const { image } = route.params as ImageUploaderScreenParamsDTO;

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <Image
        source={
          image?.uri ?? require("../../assets/images/mood-munch-logo.png")
        }
        style={styles.imageBackground}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  craftRecipeButtonContainer: {
    width: "70%", // Make the container span the entire width of the screen
    marginVertical: 20,
  },
  imageBackground: {
    resizeMode: "cover",
    width: "100%",
    maxWidth: 500,
    height: "40%",
  },
  craftButton: {
    width: "100%", // Make the button span the entire width of the container
    borderRadius: 50,
  },
  popularRecipeListContainer: {
    flex: 1,
    width: "100%",
    paddingTop: 10,
  },
  cardContainer: {
    flexDirection: "row",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  cardImage: {
    width: 100, // Adjust the width as needed
    height: 100, // Adjust the height as needed
    resizeMode: "cover",
    marginRight: 16,
    borderRadius: 8,
  },
  cardInfo: {
    flex: 1,
    justifyContent: "flex-start",
  },
  cardName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: 14,
    marginTop: 4,
    color: "#666",
  },
  recentRecipeHeaderContainer: {
    backgroundColor: "#e9a352",
    width: "65%",
    maxWidth: 250,
    paddingLeft: 20,
    paddingVertical: 10,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  recentRecipeHeaderText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
});
