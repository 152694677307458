// styles/GlobalStyles.js
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafbe8",
    // other global styles...
  },
});
