import * as React from "react";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";
import { StyleSheet, Text, View, Button } from "react-native";
import HomeScreen, {
  RecipeDetailsDto,
} from "./src/components/home/home-screen";
import CreateRecipeScreen from "./src/components/recipe/create-recipe-screen";
import RecipeDetailsScreen, {
  RecipeDto,
  RecipeIngredientDto,
} from "./src/components/recipe/recipe-details-screen";
import RecentRecipeDetailsScreen, {
  ImageObjectDto,
} from "./src/components/recipe/recent-recipe-details-screen";
import ImageUploaderScreen from "./src/components/recipe/image-uploader-screen";
import ImageReviewScreen from "./src/components/admin/image-review-screen";

// Define your linking configuration
const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ["http://localhost:19006", "yourapp://"],
  config: {
    screens: {
      Home: "home",
      Settings: "settings",
      CreateRecipeScreen: "create-recipe",
      RecipeDetailsScreen: "recipe-details-screen",
      RecentRecipeDetailsScreen: "recent-recipe-details-screen",
      ImageUploaderScreen: "image-uploader-screen",
      ImageReviewScreen: "image-review-screen",
    },
  },
};

// Define the screens in your stack as a type
export type RootStackParamList = {
  Home: undefined;
  Settings: undefined;
  CreateRecipeScreen: undefined;
  RecipeDetailsScreen: {
    selectedIngredients: string[]; // Define the expected parameter types here
    selectedDishType: string;
    selectedMood: string;
    addTwist: boolean;
  };
  RecentRecipeDetailsScreen: {
    name: string;
    id: string;
    type: string;
    description: string;
    cook_time: string;
    servings: string;
    images: { _type: string; fileName: string; url: "string" }[];
    ingredients: RecipeIngredientDto[];
    instructions: string[];
  };
  ImageUploaderScreen: {
    image: ImageObjectDto;
  };
  ImageReviewScreen: undefined;
};

// Create a stack navigator
const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
          headerTitle: "",
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: "#fafbe8",
          },
        }}
      >
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateRecipeScreen"
          component={CreateRecipeScreen}
        />
        <Stack.Screen
          name="RecipeDetailsScreen"
          component={RecipeDetailsScreen}
        />
        <Stack.Screen
          name="RecentRecipeDetailsScreen"
          component={RecentRecipeDetailsScreen}
          options={{
            headerTransparent: true,
            headerTitle: "",
            headerBackTitleVisible: false,
          }}
        />
        <Stack.Screen
          name="ImageUploaderScreen"
          component={ImageUploaderScreen}
          options={{
            headerTransparent: true,
            headerTitle: "",
            headerBackTitleVisible: false,
          }}
        />
        <Stack.Screen
          name="ImageReviewScreen"
          component={ImageReviewScreen}
          options={{
            headerTransparent: true,
            headerTitle: "",
            headerBackTitleVisible: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
