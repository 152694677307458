import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
} from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import Button from "../custom/Button";
import axios from "axios";
import { RecipeIngredientDto } from "../recipe/recipe-details-screen";
import { API_URL } from "@env";
import ActivityIndicatorWrapper from "../custom/ActivityIndicatorWrapper";

// Define the type for the navigation prop for each screen
type ImageReviewScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "ImageReviewScreen"
>;

export interface RecipeDetailsDto {
  name: string;
  id: string;
  type: string;
  description: string;
  cook_time: string;
  servings: string;
  images: string[];
  ingredients: RecipeIngredientDto[];
  instructions: string[];
}

export interface GetImagesToReviewResponse {
  id: string;
  recipeId: string;
  recipe: RecipeDetailsDto;
  image: { url: string };
}

export default function ImageReviewScreen({
  navigation,
}: {
  navigation: ImageReviewScreenNavigationProp;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [approvalKey, setApprovalKey] = useState("");
  const [imagesToReview, setImagesToReview] = useState<
    GetImagesToReviewResponse[] | null
  >(null);

  const handleFetchImagesToReview = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${API_URL}/images_to_review?approvalKey=${approvalKey}`
      );
      setImagesToReview(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveImage = async (imageToApproveId: string) => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${API_URL}/approve_image?imageId=${imageToApproveId}`
      );
      Alert.alert("Images Approved!");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const RecipeCard: React.FC<{ imageToReview: GetImagesToReviewResponse }> = ({
    imageToReview,
  }) => {
    const { recipeId, recipe, image } = imageToReview;

    return (
      <View style={styles.cardContainer}>
        <Image source={{ uri: image.url }} style={styles.cardImage} />
        <View style={styles.cardInfo}>
          <Text style={styles.cardName}>
            {recipeId} - {recipe.name}
          </Text>
          <Text style={styles.cardDescription} numberOfLines={2}>
            {recipe.description}
          </Text>
          <Button
            title="Approve"
            onPress={() => handleApproveImage(imageToReview.id)}
            containerStyle={styles.approveButton}
          />
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <ActivityIndicatorWrapper isLoading={loading}>
        <View style={styles.approvalKeyContainer}>
          <TextInput
            style={styles.input}
            onChangeText={setApprovalKey}
            value={approvalKey}
            placeholder="Auth key"
          />
          <Button
            title="Authenticate"
            onPress={handleFetchImagesToReview}
            containerStyle={styles.craftButton}
          />
        </View>
        <FlatList
          data={imagesToReview}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => <RecipeCard imageToReview={item} />}
        />
      </ActivityIndicatorWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  approvalKeyContainer: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    height: 30,
    margin: 12,
    borderWidth: 0.4,
    borderRadius: 30,
    padding: 10,
  },
  imageBackground: {
    resizeMode: "center",
    width: "100%",
    height: "20%",
  },
  craftButton: {
    width: "40%", // Make the button span the entire width of the container
    borderRadius: 50,
    height: 30,
  },
  approveButton: {
    width: "20%",
    height: 30,
    marginTop: 10,
  },
  popularRecipeListContainer: {
    flex: 1,
    width: "100%",
    paddingTop: 10,
    marginTop: 50,
  },
  cardContainer: {
    flexDirection: "row",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  cardImage: {
    width: 100, // Adjust the width as needed
    height: 100, // Adjust the height as needed
    resizeMode: "cover",
    marginRight: 16,
    borderRadius: 8,
  },
  cardInfo: {
    flex: 1,
    justifyContent: "flex-start",
  },
  cardName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: 14,
    marginTop: 4,
    color: "#666",
  },
  recentRecipeHeaderContainer: {
    backgroundColor: "#e9a352",
    width: "65%",
    maxWidth: 250,
    paddingLeft: 20,
    paddingVertical: 10,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  recentRecipeHeaderText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
});
