import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  ImageBackground,
  ScrollView,
  Dimensions,
  Alert,
} from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import axios from "axios";
import { RouteProp, useRoute } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";
import { RecipeDetailsDto } from "../home/home-screen";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import Button from "../custom/Button";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { API_URL } from "@env";
import ActivityIndicatorWrapper from "../custom/ActivityIndicatorWrapper";

const screenWidth = Dimensions.get("window").width;

// Define the type for the navigation prop for each screen
type RecentRecipeDetailsScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "RecentRecipeDetailsScreen"
>;

export interface RecipeIngredientDto {
  name: string;
  quantity: string;
  state?: string;
  optional?: boolean;
}

export interface ImageObjectDto {
  full?: string;
  raw?: string;
  regular?: string;
  small?: string;
  small_s3?: string;
  thumb?: string;
}

export interface RecipeDto {
  recipe_name: string;
  recipe_type: string;
  recipe_description: string;
  recipe_cook_time: string;
  recipe_image?: ImageObjectDto;
  servings: string;
  ingredients: RecipeIngredientDto[];
  instructions: string[];
}

export interface ImageObjectDto {
  uri: string;
  height: number;
  width: number;
}

export default function RecentRecipeDetailsScreen({
  navigation,
}: {
  navigation: RecentRecipeDetailsScreenNavigationProp;
}) {
  const route = useRoute();
  const { instructions } = route.params as RecipeDetailsDto;

  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean[]>([]);
  const [recipe, setRecipe] = useState<RecipeDetailsDto>();
  const [image, setImage] = useState<ImageObjectDto | null>(null);
  const placeholderImage = [
    { url: require("../../assets/images/mood-munch-place-holder.png") },
  ];

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });

    if (!result.canceled) {
      setImage(result.assets[0]);
      await uploadImageForReview(result.assets[0]);
    }
  };

  const uploadImageForReview = async (selectedImage: ImageObjectDto) => {
    try {
      setLoading(true);
      const manipulatedImage = await ImageManipulator.manipulateAsync(
        selectedImage.uri,
        [{ resize: { width: 500 } }], // Adjust the width as needed for compression
        { base64: true } //Get the base64 representation
      );
      const result = await axios.post(`${API_URL}/upload_image`, {
        image: {
          base64: manipulatedImage.base64,
          width: manipulatedImage.width,
          height: manipulatedImage.height,
        },
        recipe,
      });
      setLoading(false);
      Alert.alert("Image uploaded for review!");
    } catch (error) {
      setLoading(false);
      Alert.alert("Error uploading image for review");
      console.error(error);
    }
  };

  useEffect(() => {
    if (instructions) {
      setIsChecked(new Array(instructions.length).fill(false));
    }

    if (Array.isArray(instructions)) {
      setRecipe(route.params as RecipeDetailsDto);
      console.log(route.params);
      localStorage.setItem(
        "currentItem",
        JSON.stringify(route.params as RecipeDetailsDto)
      );
    } else {
      const savedItem = localStorage.getItem("currentItem");
      if (savedItem) {
        setRecipe(JSON.parse(savedItem));
      }
    }
  }, []);

  const setChecked = (index: number) => {
    const updatedChecked = isChecked.map((item, i) =>
      i === index ? !item : item
    );
    setIsChecked(updatedChecked);
  };

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <ActivityIndicatorWrapper isLoading={loading}>
        <ScrollView style={styles.scrollView}>
          <View
            style={{
              zIndex: 2,
              flex: 1,
              width: "100%",
              maxWidth: 400,
              alignSelf: "center",
              marginBottom: 20,
            }}
          >
            <SwiperFlatList
              index={0}
              showPagination
              data={recipe?.images?.length ? recipe?.images : placeholderImage}
              renderItem={({ item }) => (
                <View style={{ backgroundColor: "black", width: 500 }}>
                  <Image
                    source={{ uri: item.url }}
                    style={{
                      height: 400,
                      width: 400,
                      resizeMode: "cover",
                    }}
                  />
                </View>
              )}
            />
            <View
              style={{
                width: "20%",
                position: "absolute",
                right: 5,
                bottom: 10,
              }}
            >
              <Button
                title="Add+"
                onPress={pickImage}
                containerStyle={{ borderRadius: 50, elevation: 10 }}
              />
            </View>
          </View>
          <View style={styles.recipeDetailsContainer}>
            <Text style={styles.recipeName}>{recipe?.name}</Text>
            <View style={styles.iconsContainer}>
              <View style={styles.recipeTimeContainer}>
                <MaterialCommunityIcons
                  name="clock"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>{recipe?.cook_time}</Text>
              </View>
              <View style={styles.ingredientsCountContainer}>
                <MaterialCommunityIcons
                  name="food-variant"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>
                  {recipe?.ingredients?.length} ingredients
                </Text>
              </View>
              <View style={styles.recipeTimeContainer}>
                <MaterialCommunityIcons
                  name="clock"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>
                  {recipe?.servings} serving(s)
                </Text>
              </View>
            </View>
            <Text style={styles.recipeDescription}>{recipe?.description}</Text>
            <View style={styles.instructionsContainer}>
              <Text style={styles.recipeInstructionsHeader}>Ingredients</Text>
              {Array.isArray(recipe?.ingredients) &&
                recipe?.ingredients?.map((ingredient, index) => (
                  <View key={index} style={styles.ingredientItem}>
                    <MaterialCommunityIcons
                      name="circle"
                      size={15}
                      color="#4f1e1c"
                      style={styles.circle}
                    />
                    <Text style={styles.instructions}>
                      {ingredient.name} - {ingredient.quantity} -{" "}
                      {ingredient.state}
                    </Text>
                  </View>
                ))}
            </View>
            <View style={styles.instructionsContainer}>
              <Text style={styles.recipeInstructionsHeader}>Instructions</Text>
              {Array.isArray(recipe?.instructions) &&
                recipe?.instructions?.map((instruction, index) => (
                  <View key={index} style={styles.instructionsRow}>
                    <Checkbox
                      style={styles.checkbox}
                      value={isChecked[index]}
                      onValueChange={() => setChecked(index)}
                      color={isChecked[index] ? "#4f1e1c" : "#4f1e1c"}
                    />
                    <Text style={styles.instructions}>{instruction}</Text>
                  </View>
                ))}
            </View>
          </View>
        </ScrollView>
      </ActivityIndicatorWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  child: { width: screenWidth, justifyContent: "center" },
  text: { textAlign: "center" },
  scrollView: {
    width: "100%",
    flex: 1,
  },
  imageBackground: {
    width: "100%", // You can adjust the width and height
    height: "100%", // to fit your layout needs
    justifyContent: "center", // Align children components
    alignItems: "center",
  },
  recipeDetailsContainer: {
    width: "80%",
    maxWidth: 500,
    alignItems: "flex-start",
    alignSelf: "center",
  },
  recipeName: {
    fontSize: 20,
    fontWeight: "600",
    color: "#702412",
    alignSelf: "center",
  },
  recipeDescription: {
    color: "#702412",
    fontSize: 16,
    marginTop: 10,
    marginVertical: 10,
    paddingVertical: 10,
    textAlign: "justify",
  },
  iconsContainer: {
    alignItems: "center",
    borderWidth: 0.8,
    borderColor: "#702412",
    width: "100%",
    marginVertical: 20,
    padding: 20,
    flexDirection: "row",
  },
  recipeTimeContainer: {
    // paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    flex: 1,
  },
  ingredientsCountContainer: {
    alignItems: "center",
    paddingHorizontal: 5,
    // alignSelf: "flex-end",
    flex: 1,
    borderLeftWidth: 0.7,
    borderRightWidth: 0.7,
    borderColor: "#702412",
  },
  infoText: {
    fontSize: 14,
    color: "#702412",
    marginTop: 10,
    textAlign: "center",
  },
  checkbox: {
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
    height: 20,
    width: 20,
  },
  instructionsContainer: {
    // flexDirection: "row",
    marginVertical: 10,
    paddingVertical: 10,
    borderTopWidth: 0.3,
    width: "100%",
    maxWidth: 500,
    borderColor: "#702412",
  },
  instructionsRow: {
    flexDirection: "row",
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
    paddingVertical: 15,
  },
  instructions: {
    fontSize: 16,
    color: "#702412",
  },
  recipeInstructionsHeader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#702412",
    marginVertical: 10,
  },
  ingredientItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  circle: {
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  carouselItem: {
    backgroundColor: "white",
    borderRadius: 5,
    height: 200,
    padding: 50,
    marginLeft: 25,
    marginRight: 25,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
});
