import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, SafeAreaView, View, Text, Switch } from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import DropDownPicker from "react-native-dropdown-picker";
import { ScrollView } from "react-native-gesture-handler";
import Button from "../custom/Button";
import { RecipeDetailsScreenParamsDTO } from "./recipe-details-screen";
import axios from "axios";
import { API_URL } from "@env";
import ActivityIndicatorWrapper from "../custom/ActivityIndicatorWrapper";

// Define the type for the navigation prop for each screen
type CreateRecipeScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "CreateRecipeScreen"
>;

export default function CreateRecipeScreen({
  navigation,
}: {
  navigation: CreateRecipeScreenNavigationProp;
}) {
  const [ingredients, setIngredients] = useState([
    { label: "Chicken", value: "Chicken" },
    { label: "Black Pepper", value: "Black Pepper" },
    { label: "Salt", value: "Salt" },
    { label: "Onions", value: "Onion" },
    { label: "Paprika", value: "Paprika" },
    { label: "Olive Oil", value: "Olive Oil" },
    { label: "Rice", value: "Rice" },
  ]); // Replace with your ingredient data
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [openIngredients, setOpenIngredients] = useState(false);

  const [moods, setMoods] = useState([]); // Replace with your ingredient data
  const [selectedMood, setSelectedMood] = useState("");
  const [openMood, setOpenMood] = useState(false);

  const [dishType, setDishType] = useState([]); // Replace with your ingredient data
  const [selectedDishType, setselectedDishType] = useState("");
  const [openDishType, setOpenDishType] = useState(false);

  const [addTwist, setAddTwist] = useState(false);
  const toggleSwitch = () => setAddTwist((previousState) => !previousState);

  const handleGenerateRecipe = () => {
    const params: RecipeDetailsScreenParamsDTO = {
      selectedIngredients,
      selectedDishType,
      selectedMood,
      addTwist,
    };

    navigation.navigate("RecipeDetailsScreen", params);
  };

  const generateRecipe = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${API_URL}/choices`);
      setDishType(response.data.dishTypes);
      setMoods(response.data.mood);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateRecipe();
  }, []);

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <ActivityIndicatorWrapper isLoading={loading}>
        <Text style={styles.pageHeader}>Recipe Generator</Text>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.inputContainer}>
            <View style={styles.moodContainer}>
              <Text style={styles.dropDownHeaderText}>Mood</Text>
              <DropDownPicker
                open={openMood}
                value={selectedMood}
                multiple={false}
                items={moods}
                setOpen={setOpenMood}
                setValue={setSelectedMood}
                placeholder="Select a mood"
                dropDownContainerStyle={{
                  backgroundColor: "#fcf3c2",
                }}
                style={{ backgroundColor: "#fcf3c2" }}
              />
            </View>
            <View style={styles.dishTypeContainer}>
              <Text style={styles.dropDownHeaderText}>Dish Type</Text>
              <DropDownPicker
                open={openDishType}
                value={selectedDishType}
                multiple={false}
                items={dishType}
                setOpen={setOpenDishType}
                setValue={setselectedDishType}
                placeholder="Select a dish type"
                dropDownContainerStyle={{
                  backgroundColor: "#fcf3c2",
                }}
                style={{ backgroundColor: "#fcf3c2" }}
              />
            </View>
            <View style={styles.ingredientsContainer}>
              <Text style={styles.dropDownHeaderText}>Ingredients</Text>
              <DropDownPicker
                open={openIngredients}
                value={selectedIngredients}
                items={ingredients}
                setItems={setIngredients}
                multiple={true}
                setOpen={setOpenIngredients}
                setValue={setSelectedIngredients}
                placeholder="Select ingredients"
                searchable={true}
                searchTextInputProps={{
                  maxLength: 25,
                }}
                addCustomItem={true}
                dropDownContainerStyle={{
                  backgroundColor: "#fcf3c2",
                  maxHeight: 200,
                }}
                style={{ backgroundColor: "#fcf3c2" }}
              />
            </View>
            <ScrollView
              style={{
                flex: 1,
                maxHeight: 200,
                width: "80%",
                maxWidth: 400,
              }}
            >
              <View style={styles.ingredientsChipContainer}>
                {selectedIngredients.map((ingredient) => (
                  <View key={ingredient} style={styles.chip}>
                    <Text>{ingredient}</Text>
                  </View>
                ))}
              </View>
            </ScrollView>
            <View style={styles.addTwistContainer}>
              <Text style={styles.dropDownHeaderText}>Add twist?</Text>
              <Switch
                trackColor={{ false: "#767577", true: "#f8ce8f" }}
                thumbColor={addTwist ? "#f8ce8f" : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={toggleSwitch}
                value={addTwist}
                style={{ marginTop: 10 }}
              />
            </View>
          </View>
          <View style={styles.craftRecipeButtonContainer}>
            <Button
              title="Chef it up!"
              onPress={handleGenerateRecipe}
              containerStyle={styles.craftButton}
            />
          </View>
        </ScrollView>
      </ActivityIndicatorWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  ingredientsContainer: {
    width: "80%",
    maxWidth: 400,
    height: 50,
    marginTop: 60,
    zIndex: 1,
  },
  inputContainer: {
    alignItems: "center",
    width: "100%",
  },
  craftRecipeButtonContainer: {
    width: "80%", // Make the container span the entire width of the screen
    marginVertical: 20,
    maxWidth: 400,
    alignSelf: "center",
    zIndex: -1,
  },
  craftButton: {
    width: "100%", // Make the button span the entire width of the container
    borderRadius: 50,
  },
  moodContainer: {
    zIndex: 3,
    width: "80%",
    maxWidth: 400,
    height: 50,
    marginVertical: 20,
  },
  scrollContainer: {
    flex: 1,
    width: "100%",
    marginBottom: 30,
  },
  dishTypeContainer: {
    zIndex: 2,
    width: "80%",
    maxWidth: 400,
    height: 50,
    marginTop: 40,
  },
  addTwistContainer: {
    width: "80%",
    maxWidth: 400,
    height: 50,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dropDownHeaderText: {
    fontSize: 18,
    fontWeight: "700",
    color: "#001d23",
    marginVertical: 10,
  },
  pageHeader: {
    fontSize: 20,
    fontWeight: "700",
  },
  chip: {
    backgroundColor: "#f8ce8f",
    borderRadius: 16,
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginRight: 10,
    marginBottom: 10,
  },
  ingredientsChipContainer: {
    flexDirection: "row",
    marginTop: 50,
    flexWrap: "wrap",
    alignSelf: "flex-start",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "80%",
    maxWidth: 500,
  },
});
