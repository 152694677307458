import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  ImageBackground,
  ScrollView,
} from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import axios from "axios";
import { useRoute } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";
import { API_URL } from "@env";
import ActivityIndicatorWrapper from "../custom/ActivityIndicatorWrapper";

// Define the type for the navigation prop for each screen
type RecipeDetailsScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "RecipeDetailsScreen"
>;

export interface RecipeIngredientDto {
  name: string;
  quantity: string;
  state?: string;
  optional?: boolean;
}

export interface ImageObjectDto {
  full?: string;
  raw?: string;
  regular?: string;
  small?: string;
  small_s3?: string;
  thumb?: string;
}

export interface RecipeDto {
  recipe_name: string;
  recipe_type: string;
  recipe_description: string;
  recipe_cook_time: string;
  recipe_image?: ImageObjectDto;
  servings: string;
  ingredients: RecipeIngredientDto[];
  instructions: string[];
}

export interface RecipeDetailsScreenParamsDTO {
  selectedIngredients: string[];
  selectedDishType: string;
  selectedMood: string;
  addTwist: boolean;
}

export default function RecipeDetailsScreen({
  navigation,
}: {
  navigation: RecipeDetailsScreenNavigationProp;
}) {
  const route = useRoute();
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean[]>([]);
  const [recipe, setRecipe] = useState<RecipeDto | null>(null);
  const { selectedIngredients, selectedDishType, selectedMood, addTwist } =
    route.params as RecipeDetailsScreenParamsDTO;

  useEffect(() => {
    const generateRecipe = async () => {
      try {
        setLoading(true);
        const requestBody = {
          ingredients: selectedIngredients,
          recipeType: selectedDishType,
          mood: selectedMood,
          addTwist,
        };

        const response = await axios.post(`${API_URL}/recipe`, requestBody);
        setRecipe(response.data);
        // setIsChecked(new Array(jj?.instructions.length).fill(false));
        console.log(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    generateRecipe();
  }, []);

  useEffect(() => {
    if (recipe?.instructions) {
      setIsChecked(new Array(recipe?.instructions.length).fill(false));
    }
  }, [recipe?.instructions]);

  const setChecked = (index: number) => {
    const updatedChecked = isChecked.map((item, i) =>
      i === index ? !item : item
    );
    setIsChecked(updatedChecked);
  };

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <ActivityIndicatorWrapper isLoading={loading}>
        <ScrollView style={styles.scrollView}>
          <View style={styles.recipeDetailsContainer}>
            <Text style={styles.recipeName}>{recipe?.recipe_name}</Text>
            <View style={styles.iconsContainer}>
              <View style={styles.recipeTimeContainer}>
                <MaterialCommunityIcons
                  name="clock"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>{recipe?.recipe_cook_time}</Text>
              </View>
              <View style={styles.ingredientsCountContainer}>
                <MaterialCommunityIcons
                  name="food-variant"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>
                  {recipe?.ingredients.length} ingredients
                </Text>
              </View>
              <View style={styles.recipeTimeContainer}>
                <MaterialCommunityIcons
                  name="clock"
                  size={35}
                  color="#4f1e1c"
                />
                <Text style={styles.infoText}>
                  {recipe?.servings} serving(s)
                </Text>
              </View>
            </View>
            <Text style={styles.recipeDescription}>
              {recipe?.recipe_description}
            </Text>
            <View style={styles.instructionsContainer}>
              <Text style={styles.recipeInstructionsHeader}>Ingredients</Text>
              {recipe?.ingredients.map((ingredient, index) => (
                <View key={index} style={styles.ingredientItem}>
                  <MaterialCommunityIcons
                    name="circle"
                    size={15}
                    color="#4f1e1c"
                    style={styles.circle}
                  />
                  <Text style={styles.instructions}>
                    {ingredient.name} - {ingredient.quantity} -{" "}
                    {ingredient.state}
                  </Text>
                </View>
              ))}
            </View>
            <View style={styles.instructionsContainer}>
              <Text style={styles.recipeInstructionsHeader}>Instructions</Text>
              {recipe?.instructions.map((instruction, index) => (
                <View key={index} style={styles.instructionsRow}>
                  <Checkbox
                    style={styles.checkbox}
                    value={isChecked[index]}
                    onValueChange={() => setChecked(index)}
                    color={isChecked[index] ? "#4f1e1c" : undefined}
                  />
                  <Text style={styles.instructions}>{instruction}</Text>
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      </ActivityIndicatorWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  scrollView: {
    width: "100%",
    flex: 1,
  },
  imageBackground: {
    width: "100%", // You can adjust the width and height
    height: "100%", // to fit your layout needs
    justifyContent: "center", // Align children components
    alignItems: "center",
  },
  recipeDetailsContainer: {
    width: "80%",
    maxWidth: 500,
    alignItems: "flex-start",
    alignSelf: "center",
  },
  recipeName: {
    fontSize: 20,
    fontWeight: "600",
    color: "#702412",
    alignSelf: "center",
  },
  recipeDescription: {
    color: "#702412",
    fontSize: 16,
    marginTop: 10,
    marginVertical: 10,
    paddingVertical: 10,
    textAlign: "justify",
  },
  iconsContainer: {
    alignItems: "center",
    borderWidth: 0.8,
    borderColor: "#702412",
    width: "100%",
    marginVertical: 20,
    padding: 20,
    flexDirection: "row",
  },
  recipeTimeContainer: {
    // paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    flex: 1,
  },
  ingredientsCountContainer: {
    alignItems: "center",
    paddingHorizontal: 5,
    // alignSelf: "flex-end",
    flex: 1,
    borderLeftWidth: 0.7,
    borderRightWidth: 0.7,
    borderColor: "#702412",
  },
  infoText: {
    fontSize: 14,
    color: "#702412",
    marginTop: 10,
    textAlign: "center",
  },
  checkbox: {
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
    height: 20,
    width: 20,
  },
  instructionsContainer: {
    // flexDirection: "row",
    marginVertical: 10,
    paddingVertical: 10,
    borderTopWidth: 0.3,
    width: "100%",
    maxWidth: 500,
    borderColor: "#702412",
  },
  instructionsRow: {
    flexDirection: "row",
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
    paddingVertical: 15,
  },
  instructions: {
    fontSize: 16,
    color: "#702412",
  },
  recipeInstructionsHeader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#702412",
    marginVertical: 10,
  },
  ingredientItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  circle: {
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
  },
});
