import React, { ReactNode } from "react";
import { StyleSheet, View, ViewStyle, ActivityIndicator } from "react-native";
// import LottieView from 'lottie-react-native';

// Define a type for the component's props
type ActivityIndicatorWrapperProps = {
  isLoading: boolean;
  children: ReactNode;
  style?: ViewStyle | ViewStyle[];
};

export default function ActivityIndicatorWrapper({
  isLoading,
  children,
  style,
}: ActivityIndicatorWrapperProps) {
  const containerStyles = [styles.cont, style];
  return isLoading ? (
    <View style={containerStyles}>
      <View style={styles.lottieAnimation}>
        <ActivityIndicator
        // source={require('../assets/loading_animation.json')}
        // autoPlay
        // loop
        />
      </View>
    </View>
  ) : (
    children
  );
}

const styles = StyleSheet.create({
  cont: {
    alignSelf: "center",
    bottom: 0,
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  lottieAnimation: {
    height: 100,
    width: "100%",
  },
});
