import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../../App";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  FlatList,
  Image,
  TouchableOpacity,
} from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import Button from "../custom/Button";
import axios from "axios";
import { RecipeIngredientDto } from "../recipe/recipe-details-screen";
import { API_URL } from "@env";
import ActivityIndicatorWrapper from "../custom/ActivityIndicatorWrapper";

// Define the type for the navigation prop for each screen
type HomeScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "Home"
>;

export interface RecipeDetailsDto {
  name: string;
  id: string;
  type: string;
  description: string;
  cook_time: string;
  servings: string;
  images: { _type: string; fileName: string; url: "string" }[];
  ingredients: RecipeIngredientDto[];
  instructions: string[];
}

export default function HomeScreen({
  navigation,
}: {
  navigation: HomeScreenNavigationProp;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [recentRecipes, setRecentRecipes] = useState<RecipeDetailsDto[] | null>(
    null
  );

  const generateRecipe = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`${API_URL}/recent_recipes`);
      setRecentRecipes(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateRecentRecipeDetailsPage = (recipe: RecipeDetailsDto) => {
    navigation.navigate("RecentRecipeDetailsScreen", recipe);
  };

  useEffect(() => {
    generateRecipe();
  }, []);

  const RecipeCard: React.FC<{ recipe: RecipeDetailsDto }> = ({ recipe }) => {
    const { name, description, images } = recipe;

    return (
      <TouchableOpacity
        style={styles.cardContainer}
        onPress={() => handleNavigateRecentRecipeDetailsPage(recipe)}
      >
        <Image
          source={
            images?.length
              ? { uri: images[0].url }
              : require("../../assets/images/mood-munch-place-holder.png")
          }
          style={styles.cardImage}
        />
        <View style={styles.cardInfo}>
          <Text style={styles.cardName}>{name}</Text>
          <Text style={styles.cardDescription} numberOfLines={2}>
            {description}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={[styles.container, GlobalStyles.container]}>
      <ActivityIndicatorWrapper isLoading={loading}>
        <Image
          source={require("../../assets/images/mood-munch-logo.png")}
          style={styles.imageBackground}
        />
        <View style={styles.craftRecipeButtonContainer}>
          <Button
            title="Sizzle Up a Recipe!"
            onPress={() => navigation.navigate("CreateRecipeScreen")}
            containerStyle={styles.craftButton}
          />
        </View>
        <View style={styles.popularRecipeListContainer}>
          <View style={styles.recentRecipeHeaderContainer}>
            <Text style={styles.recentRecipeHeaderText}>
              Recently Generated Recipes
            </Text>
          </View>
          <FlatList
            data={recentRecipes}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => <RecipeCard recipe={item} />}
          />
        </View>
      </ActivityIndicatorWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  craftRecipeButtonContainer: {
    width: "70%", // Make the container span the entire width of the screen
    marginVertical: 20,
  },
  imageBackground: {
    resizeMode: "center",
    width: "100%",
    height: "20%",
  },
  craftButton: {
    width: "100%", // Make the button span the entire width of the container
    borderRadius: 50,
  },
  popularRecipeListContainer: {
    flex: 1,
    width: "100%",
    paddingTop: 10,
  },
  cardContainer: {
    flexDirection: "row",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  cardImage: {
    width: 100, // Adjust the width as needed
    height: 100, // Adjust the height as needed
    resizeMode: "cover",
    marginRight: 16,
    borderRadius: 8,
  },
  cardInfo: {
    flex: 1,
    justifyContent: "flex-start",
  },
  cardName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: 14,
    marginTop: 4,
    color: "#666",
  },
  recentRecipeHeaderContainer: {
    backgroundColor: "#e9a352",
    width: "65%",
    maxWidth: 250,
    paddingLeft: 20,
    paddingVertical: 10,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  recentRecipeHeaderText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
});
